//Mix Controls
.gea-mix-container-controls{
	text-align: center;
	font-size: 0;
	margin: calc(15px - 0.5rem) 0;
}

.gea-mix-container-controls button{
	display: inline-block;
	padding: 1rem;
	font-size: 1rem;
	margin: 0.5rem;
	cursor: pointer;
	background: #237A57;
	color: white;
	transition: all ease 0.25s;
	outline: none;
	border: none;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
} 

.gea-mix-container-controls button:hover{
	background: #5a9b81;
}

.gea-mix-container-controls button h2{
	font-size: 1rem;
	display: inline;
	margin: 0;
}

.gea-mix-container-controls .mixitup-control-active{
	background: #093028;
}

.gea-mix-container-controls .mixitup-control-active:hover{
	background: #093028;
}

//Mix Cards

.gea-card{
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}

.gea-card-image{
	width: 100%;
	padding-bottom: 42.5%;
	position: relative;
	background: $white;
}

.gea-card-image a{
	display: block;
}

.gea-card-image img{
	width: auto;
	max-width: 65%;
	max-height: 65px;
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transition: all 0.25s ease;
}

.gea-card-image img:hover{
	transform: scale(1.1);
}

.gea-card-content{
	padding: 1rem 1rem calc(1rem + 0.5rem);
	//Black Background
	background: #232526;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	//Green Background
	background: #093028;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, #237A57, #093028);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, #237A57, #093028); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: white;
	text-align: center;
}

.gea-card-content h3{
	margin: 0 0 0.25rem;
	line-height: 1.5;
}

.gea-card-content h3 a{
	color: white;
}

.gea-card-content a{
	color: white;
}

.gea-card-content ul{
	padding: 0;
	margin: 0;
	list-style: none;
	font-size: 0;
}

.gea-card-content ul li{
	display: inline-block;
	font-size: 1rem;
	border-right: 1px solid white;
	padding: 0 5px 0 0;
	margin: 0 5px 0 0;
}

.gea-card-content ul li:last-child{
	border-right: 0;
	padding: 0;
	margin: 0;
}