//General

html, body{
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}

//Links

a{
	transition: all ease 0.25s;
}

a:hover{
	transition: all ease 0.25s;
	text-decoration: none;
}

.hvr-underline-from-left{
	vertical-align: top;
}

.hvr-underline-from-left:before{
	height: 1px;
	background: $white;
}

//Fonts

h1{
	margin: 0 0 1rem;
}

h3{
	font-size: 1.25rem;
}

h4{
	font-size: 1rem;
	text-transform: uppercase;
	text-align: center;
	border-bottom: 1px solid #d5d5d5;
	padding: 0 0 0.5rem;
	margin: 0 0 calc(0.5rem + 2.5px);
}

p{
	margin: 0 auto;
	color: #666666;
	max-width: 600px;
}

//Images

img{
	width: 100%;
	height: auto;
	display: block;
}

.gea-banners{
	text-align: center;
}

.gea-banners img{
	transition: 0.25s ease all;
	-webkit-backface-visibility: hidden;
	display: inline-block;
	max-width: 570px;
}

.gea-banners img:hover{
	opacity: 0.95;
	-webkit-backface-visibility: hidden;
}

.gea-col{
	margin: 15px 0;
}

@media (max-width:480px){
	.gea-col{
		margin: 7.5px 0;
	}
}

//Header

.gea-header{
	text-align: center;
}

.gea-header img{
	width: 100px;
	height: auto;
	display: inline-block;
	margin: 0 0 12.5px;
}

.gea-footer{
	text-align: center;
	margin: 30px 0 0;
}

//Wrapper

.gea-wrapper{
	padding: 45px 0;
	background: #e5e5e5;
}